import {
  TYPE_DASHBORAD_TOGGLE_ALERTS,
  TYPE_DASHBORAD_TOGGLE_MARKETS,
  TYPE_DASHBORAD_TOGGLE_OVERVIEW,
} from '../actions/dashboard';

const initState = {
  sideBarState: localStorage.getItem('sideBarState') || null,
  isOpenMarketsSideBar: !!localStorage.getItem('isOpenMarketsSideBar'),
};

const reducerDashboard = (state = initState, action) => {
  let sideBarState;
  switch (action.type) {
    case TYPE_DASHBORAD_TOGGLE_MARKETS:
      if (state.isOpenMarketsSideBar) {
        localStorage.removeItem('isOpenMarketsSideBar');
      } else {
        localStorage.setItem('isOpenMarketsSideBar', 'true');
      }
      return { ...state, isOpenMarketsSideBar: !state.isOpenMarketsSideBar };
    case TYPE_DASHBORAD_TOGGLE_ALERTS:
      sideBarState = state.sideBarState === 'alarmView' ? null : 'alarmView';
      if (sideBarState === null) {
        localStorage.removeItem('sideBarState');
      } else {
        localStorage.setItem('sideBarState', sideBarState);
      }
      return { ...state, sideBarState };
    case TYPE_DASHBORAD_TOGGLE_OVERVIEW:
      sideBarState = state.sideBarState === 'overview' ? null : 'overview';
      if (sideBarState === null) {
        localStorage.removeItem('sideBarState');
      } else {
        localStorage.setItem('sideBarState', sideBarState);
      }
      return { ...state, sideBarState };
    default:
      return state;
  }
};

export default reducerDashboard;
