import { combineReducers } from 'redux';
import config from 'redux/reducers/settings';
import bitrage from './bitrage';
import dashboard from './dashboard';

export default combineReducers({
  settings: config,
  bitrage,
  dashboard,
});
