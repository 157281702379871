import $ from 'jquery';
import Api from 'api/index';

import {
  RESET_CONFIG,
  PUT_CONFIG,
  RECEIVE_CONFIG,
  STOP_BOT_CORE,
  START_BOT_CORE,
  RECEIVE_EXCHANGES,
  RECEIVE_IMAP,
  RECEIVE_WEBHOOKS,
  SET_STATE,
  FORCE_CONFIG,
  SET_COREMEM_DATA,
  SET_LICENSE_DATA,
  LOADING,
  CLEAR_CONFIG,
  SET_ERROR,
  SET_CURRENT_SYMBOL,
  SET_CURRENT_EXPORT_SYMBOL,
  SET_ORDERS,
  NEW_ORDERS,
  NEW_PNL_HISTORY,
} from 'redux/actions';
import _ from 'lodash';

const initConfig = {
  webhooks: {},
  imap_listener: {},
  exchanges: {},
  autoconfig: {},
  pairs: {},
  strategies: {},
};

let changeFlags = {};

export const isChangedSubConfig = keys => {
  return changeFlags[keys];
};

const realState = {
  config: initConfig,
  IS_CHANGED: false,
  active: false,
  isAuth: false,
  metamask: false,
  authRequired: true,
  twoFARequired: false,
  gwInfo: {
    selected: '',
    newWallet: '',
  },
  corememData: {
    memory: {},
  },
  licenseData: {
    strategies: {},
  },
  socketData: {
    orders: {},
    pnlHistory: {},
  },
  orders: {},
  isLoadedConfig: false,
  loading: false,
  error: {
    error: null,
    previousRoute: '/',
  },
  currentSymbol: {
    exchange: null,
    pair: null,
  },
  currentExportSymbol: {
    exchange: null,
    pair: null,
  },
  balances: [],
  sideBarState: '',
  isOpenSideBar: false,
  isOpenMarketsSideBar: true,
  alertList: [],
};

function parseConfig(state) {
  if (state.config.GUI) {
    state.authRequired = state.config.GUI.authentication.login;
    state.twoFARequired = state.config.GUI.authentication.twoFA;
    state.active = state.config.GUI && state.config.GUI.start && realState.config.GUI.start;
  }
  if (state.config.bot) {
    state.gwInfo.selected = state.config.bot.gunthy_wallet;
  }
  state.gwInfo.newWallet = '';
  return state;
}

function changeConfig(state, value, key, ...parents) {
  const newState = { ...state, config: forceState(state.config, value, key, ...parents) };
  const realValue = getStateValue(realState.config, key, ...parents);

  const keys = parents.reverse();
  let flagKey = keys.concat(key).join(':');
  if (_.isEqual(value, realValue)) {
    delete changeFlags[flagKey];
  } else {
    changeFlags[flagKey] = true;
  }

  newState.IS_CHANGED = Object.keys(changeFlags).length > 0;

  if (newState.IS_CHANGED) {
    Api.havePendingConfigChanges(true).then(res => {
      console.log('==========havePendingConfigChanges======== ======== =      = = = ==');
    });
  }

  // detect new or updated pairs
  if (!_.isNil(value) && Object.keys(value).includes('override')) {
    const exchange = _.last(keys);
    const pair = key;
    const pairKey = `${exchange}_${pair}`;
    const isUpdated = !_.isNil(realState.config.pairs[exchange]) && !_.isNil(realState.config.pairs[exchange][pair]);

    if (_.isNil(newState?.NEW_PAIRS)) {
      newState.NEW_PAIRS = [];
    }
    if (_.isNil(newState?.UPDATED_PAIRS)) {
      newState.UPDATED_PAIRS = [];
    }
    if (!isUpdated) {
      newState.NEW_PAIRS = [...newState.NEW_PAIRS, pairKey];
    } else {
      newState.UPDATED_PAIRS = [...newState.UPDATED_PAIRS, pairKey];
    }
  }

  return parseConfig(newState);
}

function forceConfig(state, value, key, ...parents) {
  let data = realState.config;
  parents = parents.reverse();
  for (let i = 0; i < parents.length; i++) {
    let p_key = parents[i];
    if (p_key !== null) data = data[p_key];
    if (data === undefined || (!Array.isArray(data) && typeof data !== 'object')) return state;
  }
  data[key] = value;
  let flagKey = parents.concat(key).join(':');
  delete changeFlags[flagKey];
  return parseConfig({ ...state, config: $.extend(true, {}, realState.config) });
}

function clearConfig(state) {
  realState.config = { ...initConfig };
  return resetConfig(state);
}

function forceState(state, value, key, ...parents) {
  let newState = { ...state };
  let data = newState;
  parents = parents.reverse();
  for (let i = 0; i < parents.length; i++) {
    let p_key = parents[i];
    if (data[p_key] === undefined || (!Array.isArray(data[p_key]) && typeof data[p_key] !== 'object')) return state;
    data[p_key] = { ...data[p_key] };
    data = data[p_key];
  }
  data[key] = value;
  return newState;
}

function getStateValue(state, key, ...parents) {
  let newState = { ...state };
  let data = newState;
  parents = parents.reverse();
  for (let i = 0; i < parents.length; i++) {
    let p_key = parents[i];
    if (data[p_key] === undefined || (!Array.isArray(data[p_key]) && typeof data[p_key] !== 'object')) return state;
    data[p_key] = { ...data[p_key] };
    data = data[p_key];
  }
  return data[key];
}

function resetConfig(state, strOfKeys = '') {
  let newState = { ...state };
  if (strOfKeys) {
    const keys = strOfKeys.split(':').reverse();
    const value = getStateValue(realState.config, ...keys);
    newState = changeConfig(newState, value, ...keys);
  } else {
    changeFlags = {};
    newState.IS_CHANGED = false;
    newState.config = $.extend(true, {}, realState.config);
  }
  return parseConfig(newState);
}

const config = (state = realState, action) => {
  switch (action.type) {
    case RESET_CONFIG:
      return resetConfig(state, action.strOfKeys);

    case RECEIVE_CONFIG:
      state.isLoadedConfig = true;
      state.NEW_PAIRS = [];
      state.UPDATED_PAIRS = [];
      realState.config = { ...realState.config };
      Object.keys(action.config).forEach(key => {
        realState.config[key] = action.config[key];
      });
      return resetConfig(state);

    case RECEIVE_EXCHANGES:
      return forceConfig(state, action.exchanges, 'exchanges');

    case RECEIVE_IMAP:
      return forceConfig(state, action.imap_listener, 'imap_listener');

    case RECEIVE_WEBHOOKS:
      return forceConfig(state, action.webhooks, 'webhooks');

    case PUT_CONFIG:
      return changeConfig(state, action.value, ...action.keys);

    case CLEAR_CONFIG:
      return clearConfig(state);

    case STOP_BOT_CORE:
      return forceConfig(state, false, 'start', 'GUI');
    case START_BOT_CORE:
      return forceConfig(state, true, 'start', 'GUI');

    case SET_STATE:
      return forceState(state, action.value, ...action.keys);

    case FORCE_CONFIG:
      return forceConfig(state, action.value, ...action.keys);

    case SET_COREMEM_DATA:
      return forceState(state, action.corememData, 'corememData');

    case SET_LICENSE_DATA:
      return forceState(state, action.payload, 'licenseData');

    case LOADING:
      return forceState(state, action.loading, 'loading');

    case SET_CURRENT_SYMBOL:
      return forceState(state, action.symbol, 'currentSymbol');

    case SET_CURRENT_EXPORT_SYMBOL:
      return forceState(state, action.symbol, 'currentExportSymbol');

    case SET_ORDERS: {
      let { key, orders } = action;
      state.socketData.orders[key] = orders;
      return { ...state };
    }

    case NEW_ORDERS: {
      let { key, orders } = action;
      const prev_orders = state.socketData.orders[key] || [];
      prev_orders.unshift(...orders);
      return forceState(state, prev_orders.slice(0, 500), key, 'orders', 'socketData');
    }

    case SET_ERROR:
      const error = {
        error: action.error,
        previousRoute: action.previousRoute || '/',
      };
      return forceState(state, error, 'error');

    case NEW_PNL_HISTORY: {
      const history = state.socketData.pnlHistory[action.key] || [];
      history.push(...action.history);
      return forceState(state, history, action.key, 'pnlHistory', 'socketData');
    }
    default:
      return state;
  }
};

export default config;
