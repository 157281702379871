import {
  TYPE_RELOAD_ACTIVE_TABLE,
  TYPE_RELOAD_WALLET_TABLE,
  TYPE_RELOAD_TRIPLE_TABLE,
  TYPE_RELOAD_TRADING_TABLE,
} from '../actions/bitrage';

const initState = {
  triplePerformances: false,
  walletBalances: false,
  activeTriple: false,
};

const reducerBitrage = (state = initState, action) => {
  switch (action.type) {
    case TYPE_RELOAD_TRIPLE_TABLE:
      return { ...state, triplePerformances: !state.triplePerformances };
    case TYPE_RELOAD_WALLET_TABLE:
      return { ...state, walletBalances: !state.walletBalances };
    case TYPE_RELOAD_ACTIVE_TABLE:
      return { ...state, activeTriple: !state.activeTriple };
    case TYPE_RELOAD_TRADING_TABLE:
      return { ...state, tradingHistory: !state.tradingHistory };
    default:
      return state;
  }
};

export default reducerBitrage;
